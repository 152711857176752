
$header-height: 45px;
$menu-bar-width: 95px;
$sider-bar-width: 280px;
$sider-header-height: 40px;
$background-layout-primary: white;
$background-layout-secondary: rgb(249 249 249);
$border-layout-color: #dbdbdb;
$font-size: 13px;
$num-slider-headers: 2;
$num-slider-footer: 1;
$scroll-width: 6px;
$font-size-base: 12px;

[class^="ant-space"], [class*=" ant-space"], [class^="ant-segmented"], [class*=" ant-segmented"] {
    font-size: $font-size-base;
}

::-webkit-scrollbar {
    width: $scroll-width;
    height: 6px;
    background-color: rgb(249 249 249);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a6a6a6;
}

.ant-dropdown-menu-title-content {
    font-size: $font-size-base;
}

.alert-justify-center {
    display: flex;
    align-items: center;
}

.alert-with-icon-button {
    button {
        width: 16px !important;
        height: 16px !important;
    }
}

.filled-table-panel {
    div:last-child[role="grid"] {
        div[role="gridcell"] {
            border-bottom: 0;
        }
    }
}

/* MAIN CSS FOR DASHBOARD */
#dashboard-layout{
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    min-width: 1020px;
    max-width: 2000px;
    // border-left: 1px solid #a6a6a6;
    // border-right: 1px solid #a6a6a6;

    /* OVERRIDES AND MIXIN */
    @mixin toMaxHeight($offset) {
        height: calc(100vh - $offset);
    }

    div[role='tooltip'] {
        z-index: 99999;
        font-size: 10px;
    }

    /* ANT OVERRIDES */
    .ant-empty-description {
        font-size: $font-size !important;
    }

    .ant-dropdown {
        * {
            box-shadow: unset !important;
        }

        .ant-dropdown-menu {
            border: 1px solid $border-layout-color;
            .ant-dropdown-menu-item {
                * {
                    font-size: $font-size-base;
                }
            }
        }
    }

    .ant-tooltip-inner {
        font-size: 13px !important;
    }

    .ant-result {
        .ant-result-subtitle {
            font-size: 13px;
        }
    }

    .ant-card, .ant-card-head {
        border-radius: 2px;
    }

    .ant-divider {
        margin: 12px 0 5px;

        .ant-divider-inner-text {
            color: black;
            display: flex;
            align-items: center;

            .override-font-size-16 {
                font-size: 16px !important;
            }
        }
    }

    .ant-card-head {
        background-color: #f3f3f3;
        font-size: 13px !important;
    }

    /* DASHBOARD SECTIONS */
    .dashboard-header {
        height: $header-height;
        background-color: white;
        border-bottom: 1px solid $border-layout-color;
        line-height: $header-height;
        color: black;
        padding-left: 20px;
        padding-right: 20px;

        .dashboard-header-buttons-right {
            button {
                margin-left: 5px;
            }
        }
    }

    /* section under header */
    .dashboard-body {
        @include toMaxHeight($header-height);
        width: 100%;
        background-color: white;
        overflow: hidden;
        overflow-x: auto;

        /* left */
        .dashboard-side-nav {
            overflow: hidden;
            border-right: 1px solid $border-layout-color;
            @include toMaxHeight($header-height);
            background-color: $background-layout-secondary;
            overflow-y: auto;
            padding: 4px;

            .ant-menu {
                background-color: $background-layout-secondary;
                border: 0;

                .ant-menu-item {
                    flex-direction: column;
                    padding: 10px !important;
                    height: auto;
                    border-radius: 4px;
                    color: black;

                    &.ant-menu-item-selected {
                        color: black;
                    }

                    .ant-menu-title-content {
                        margin-top: 5px;
                        font-size: 10px;
                        margin-inline-start: unset;
                        line-height: 10px;
                    }
                }

                .ant-menu-item-disabled {
                    cursor: default;
                }

                .ant-menu-item-selected {
                    background-color: rgb(232, 232, 232);
                    color: inherit;

                    .ant-menu-item-selected-bar {
                        width: 3px;
                        background-color: #4096ff;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .ant-menu-item-active {
                    background-color: rgb(241, 241, 241);
                }
            }
        }

        /* right */
        .dashboard-data {
            width: calc(100% - ($menu-bar-width + 2px));

            .rs-panel-header {
                font-size: 13px !important;


            }
        }
    }

    .dashboard-side-nav-toolbar {
        height: $header-height;
        font-size: $font-size;
        padding: 5px 10px 5px 10px;
        border-bottom: 1px solid $border-layout-color;

        .ant-input-affix-wrapper {
            border-radius: unset;
        }

        .ant-input-group-addon {
            button {
                border-radius: unset;
            }
        }

        .ant-input {
            font-size: 13px;
        }

        .anticon {
            font-size: $font-size-base;
        }
    }

    .dashboard-data-side-nav {
        @extend .dashboard-side-nav;
        overflow: hidden !important;
        padding: 0 !important;
        color: black;

        .dashboard-data-side-nav-data{
            overflow-y: auto;
            @include toMaxHeight($header-height * 4);
        }

        .rs-sidenav-item {
            font-size: $font-size-base !important;
            padding: 9px !important;
        }

        .rs-sidenav-item-active {
            color: black !important;
            background-color: rgba(47, 53, 69, 0.13);
        }

        .rs-sidenav-item-disabled {
            color: grey !important;
        }

        .dashboard-data-side-nav-footer {
            border-top: 1px solid $border-layout-color;
        }
    }

    .dashboard-data-with-side-nav {
        width: calc(100% - ($menu-bar-width + 2px) - ($sider-bar-width + 2px));
        overflow-y: auto;
        height: calc(100vh - $header-height);

        .panel-error svg {
            font-size: 40px !important;
        }

        .ant-descriptions-view {
            border: 0;

            .ant-descriptions-item-label {
                color: rgba(0, 0, 0, 0.80);
                background-color: rgba(243, 243, 243, 0.78);
            }

            .ant-descriptions-item-content {
                color: black;
            }
        }

        .ant-divider-horizontal.ant-divider-with-text-left::before {
            width: 8px;
        }

        .ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
            border-block-start-color: #817d7d2e;
        }
    }

    .dashboard-data-section {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }
    .dashboard-data-title {
        padding: 12px;
        height: $header-height - 1;
        min-height: $header-height - 1;
    }

    .dashboard-data-menu-toolbar {
        border-top: 1px solid $border-layout-color;
        border-bottom: 1px solid $border-layout-color;
        height: $header-height + 1;
        min-height: $header-height + 1;
        overflow-x: hidden !important;

        .rs-nav {
            .rs-nav-item {
                font-size: 13px !important;
                height: $header-height - 1;
                line-height: $header-height - 19px;
            }

            .rs-nav-item-active:before {
                background-color: rgb(92 92 92) !important;
            }

            .rs-nav-item-active {
                color: black !important;
            }

            .rs-nav-bar {
                border-top: none !important;
            }
        }
    }

    .dashboard-data-content {
        padding: 0 15px 15px 15px;
        flex: 1;
        overflow-y: scroll;
    }

    .dashboard-feedback {
        .ant-result-icon {
            margin-block: 5px !important;
            margin-bottom: 10px !important;
        }

        .ant-result-title {
            margin-block: 5px !important;
            margin-bottom: 0 !important;

        }
    }
}

.icon-18 {
    svg {
        font-size: 18px !important;
    }
}

.consumption-fields {
    .consumption-fields-plus-minus {
        .ant-select-selector {
            border-right: 0;
        }
    }

    .consumption-fields-units {
        .ant-select-selector {
            border-left: 0;
        }
    }

    .consumption-fields-value {
        .ant-input-number {
            width: unset;
            border-right: 1px solid #d9d9d9;
        }

        .ant-input-number-affix-wrapper {
            width: unset;
            //border-radius: unset;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
            //border-right: 0;
        }
    }
}

.supply-costs-fields {
    .supply-costs-fields-fix {
        .ant-input-number-group-addon {
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
            border-right: 0;
        }

        .ant-input-number-in-form-item {
            border-radius: 0 !important;
        }
    }

    .supply-costs-fields-variable {
        .ant-input-number-group-addon {
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
            border-right: 0;
        }

        .ant-input-number-affix-wrapper {
            border-radius: 0;
        }
    }

    .supply-costs-fields-vat {
        .ant-input-number-affix-wrapper {
            border-radius: 0;
        }

        .ant-input-number-in-form-item {
            border-radius: 0 !important;
        }
    }
}

.fix-with-input-number {
    .ant-input-number-group-wrapper {
        width: 100%;
    }
    .ant-input-number {
        width: 100%;
    }
}

.promotion-energy-liquidation-periods-table {
    div[aria-colindex="1"] {
        background-color: rgba(231, 231, 231, 0.13);
    }

}

.rs-between-tables-title {
    height: 30px;
    margin-top: 10px;
    padding: 5px 10px 5px 20px;
    color: #69696b;

    > .ant-row > .rs-table-cell-title-text {
        font-size: $font-size-base;
        padding-left: 5px;
    }
}

.rs-between-tables-row-title {
    height: 30px;
    padding: 5px 10px 5px 10px;
    background-color: rgba(220, 220, 220, 0.1294117647);
    border-bottom: 1px solid var(--rs-border-secondary);
    color: #69696b;

    > .ant-row > .rs-table-cell-title-text {
        font-size: $font-size-base;
        padding-left: 5px;
    }
}

.rs-table-cell-title-row {
    //margin-top: 15px;
    height: 30px;
    margin-top: 10px;
    padding: 5px 10px 5px 20px;
    //background-color: rgba(220, 220, 220, 0.1294117647);
    //border-bottom: 1px solid var(--rs-border-secondary);
    color: #69696b;

    > .ant-row > .rs-table-cell-title-text {
        font-size: $font-size-base;
        padding-left: 5px;
    }
}

.rs-table-row-divider {
    border-bottom: 1px dashed #dbdbdb;
    overflow: hidden !important;
}

.rs-table-fix-top-border {
    border-bottom: 1px solid var(--rs-border-secondary) !important;
}

.rs-table-cell-grey > .rs-table-cell-content {
    background-color: rgba(220, 220, 220, 0.1294117647) !important;
}

.rs-table-cell-green > .rs-table-cell-content {
    background-color: #f5fffa !important;
}

.rs-table-cell-content {
    font-size: $font-size-base;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}


.rs-table-column-group-header-content:has(.rsuite-table-group-inline) {
    padding: 4px !important;
    /* background-color: #2F3545; */
}

.rs-table-cell-header {
    div[role="columnheader"] {
        background-color: #dcdcdc21;
        /* color: #000000d6; */
    }
}

.rs-table-body-row-wrapper {
    .rs-table-cell-content {
        color: rgb(0 0 0 / 80%) !important;

        &:has(.ant-tag) {
            padding: 4px !important;
        }
    }
}

.site-space-compact-wrapper .site-input-split {
    background-color: #fff;
}

.site-space-compact-wrapper .site-input-right:not(.ant-input-rtl) {
    border-left-width: 0;
}

.site-space-compact-wrapper .site-input-right:not(.ant-input-rtl):hover,
.site-space-compact-wrapper .site-input-right:not(.ant-input-rtl):focus {
    border-left-width: 1px;
}

.site-space-compact-wrapper .site-input-right.ant-input-rtl {
    border-right-width: 0;
}

.site-space-compact-wrapper .site-input-right.ant-input-rtl:hover,
.site-space-compact-wrapper .site-input-right.ant-input-rtl:focus {
    border-right-width: 1px;
}

[class^="ant-popover"], [class*=" ant-popover"] {
    font-size: $font-size-base !important;
}

.ant-popover {
    .ant-popconfirm-message {
        margin-bottom: 0;
    }

    .ant-popconfirm-message-title {
        font-size: $font-size-base !important;
    }

    .ant-popconfirm-description {
        font-size: $font-size-base !important;
    }

    button span {
        font-size: $font-size-base !important;
    }

    .ant-popconfirm-buttons {
        margin-top: 15px;
    }
}

.rs-loader-backdrop, .panel-error  {
    z-index: 500 !important;
    background-color: whitesmoke !important;
    border: 1px solid #e3e3e3;
}

.rs-loader-spin {
    z-index: 505 !important;
}

.rs-back-dop-container-custom {
    & > .rs-back-drop-container-active {
        cursor: progress !important;
    }

    & > .rs-back-drop-container-no-border > .rs-loader-wrapper {
        & > .rs-loader-backdrop {
            border: 0 !important;
        }
    }

    & > .rs-back-drop-container-no-background {
        background: none;

        & > .rs-sidenav{
            background: none;
        }

        .rs-sidenav-item {
            background: none;
        }
    }

    & > .rs-back-drop-container-no-background > .rs-loader-wrapper {
        & > .rs-loader-backdrop {
            background: none;
        }
    }
    & > .rs-back-drop-container-background-white > .rs-loader-wrapper {
        & > .rs-loader-backdrop {
            background: white !important;
        }
    }

    & > .rs-back-drop-container-top > .rs-loader-wrapper {
        & > .rs-loader {
            padding-top: 20px !important;
            align-items: unset !important;
            -ms-flex-pack: unset !important;
            -webkit-box-align: unset !important;
            -ms-flex-align: unset !important;
            -webkit-box-pack: unset !important;
        }
    }

    & > .rs-back-drop-container-without-loader > .rs-loader-wrapper {
        & > .rs-loader > .rs-loader-spin {
            display: none !important;
        }
    }
}


.rs-loader-content {
    z-index: 510 !important;
}

.rs-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

#global_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: wait;
    z-index: 99;
    visibility: hidden;
}

.data-picker-cell, .input-number-cell, .input-text-cell {
    /* border-radius: unset !important; */
    height: 25px !important;
    margin-top: -5px;
    input {
        font-size: 12px !important;
        /* border-radius: unset !important; */
    }

    .ant-input-number-group-addon {
        /* border-radius: unset !important; */
        font-size: 12px !important;
    }
}

.data-picker-cell {
    margin-top: -6px;
}

.data-picker-segment, .input-number-cell, .input-text-cell {
    /* border-radius: unset !important; */
    height: 25px !important;
    width: 100px;

    input {
        font-size: 12px !important;
        /* border-radius: unset !important; */
    }

    .ant-input-number-group-addon {
        /* border-radius: unset !important; */
        font-size: 12px !important;
    }
}

.rs-table-last-as-total-top .rs-table-body-row-wrapper .rs-table-row:last-child {
    border-top: 1px solid #c9c9c9;
}

.rs-table-last-as-total-top-bottom .rs-table-body-row-wrapper .rs-table-row:last-child .rs-table-cell {
    border-top: 1px solid #c9c9c9 !important;
    border-bottom: 3px solid #c9c9c9 !important;
    background-color: #f7f7f7 !important;
}

.rd-table-edit-disabled {
    svg {
        cursor: not-allowed !important;
        color: #b5b5b5 !important;
    }
}

.rd-cell-select-wrap {
    .ant-select  {
        * {
            font-size: 13px !important;
        }
    }
}

.rd-cell-select-dropdown {
    .ant-select-item-option-content {
        font-size: 13px !important;
    }
}

.rs-row-title-fix-parent {
    margin-right: auto
}

.input-data-cell-align-center input {
    text-align: center !important;
}

.input-data-cell-align-left input {
    text-align: left !important;
}

.input-data-cell-align-right input {
    text-align: right !important;
}

.rd-smaller-data-picker-style * {
    font-size: 12px !important;
}

.rd-smaller-data-picker-style .ant-picker-body {
    /* padding: 8px 8px !important; */
}

.antd-table-such-as-react-suite {
    .ant-table-fixed {
        table-layout: fixed;
    }

    .ant-table-tbody > tr > td {
        word-wrap: break-word;
        word-break: break-all;
    }

    .ant-table-thead .ant-table-cell {
        font-weight: normal;
        word-wrap: normal;
    }

    .ant-table-tbody .ant-table-cell {
        color: rgba(0, 0, 0, 0.8) !important;
    }
    .ant-table-wrapper .ant-table.ant-table-small .ant-table-title, .ant-table-wrapper .ant-table.ant-table-small .ant-table-footer, .ant-table-wrapper .ant-table.ant-table-small .ant-table-cell, .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th, .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td, .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th, .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
        padding: 5px 5px;
    }

    .ant-table-container {
        border: 0 !important;

        .ant-table-cell:last-child {
            border-right: 0 !important;
        }
    }
}

.pointer-cursor {
    cursor: pointer !important;
}



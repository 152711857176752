body, html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  background: #f8f8f8;
  /* font-family: 'Montserrat', sans-serif !important; */
}

.devel-banner {
  position: fixed;
  font-weight: bold;
  font-family: system-ui;
  bottom: 0;
  /* right: -1px; */
  color: white;
  background-color: #c30000;
  box-shadow: 0 4px 8px 0;
  border-radius: 3px 3px 3px 3px;
  font-size: 12px;
  padding: 5px 10px;
  z-index: 999999999;
}

.center-vh {
  min-height: 100vh;
  display: flex;
  background: #f9f9f900;
  justify-content: center;
  align-items: center;
}

.teko-font {
  font-family: Teko,Saira Extra Condensed,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif!important;
}

.teko-font-error-size {
  font-size: 66px;
  line-height: 50px;
}

.ant-message-notice-content {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}

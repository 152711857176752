
.teko-font-rdmes {
    padding-top: 12px ;
    font-size: 88px;
    line-height: 50px;
    color: #343a40;
}

.teko-font-rdmes-slogan {
    font-size: 20px;
}